<template>
	<data-card
		:customClass="'mb-0'"
		:background-color="backgroundColor"
		:title="title"
		:type="type"
		:value="wrapperValue"
		:is-loading="isLoading"
	/>
</template>

<script>
	import DataCard from "@common-components/data-card/DataCard.vue";
	import DataCardTypes from "@common-components/data-card/data-card-types";
	import Colors from "@configs/colors";
	import { computed } from '@vue/composition-api';
	import { formatNumber } from '@/utils/ui-helper';

	export default {
		components: {
			DataCard,
		},
		props: {
			isLoading: {
				type: Boolean,
				required: true,
			},
			loadFail: {
				type: Boolean,
				required: true,
			},
			lastUpdate: {
				type: String,
			},
			value: {
				type: Object,
			},
			onReload: {
				type: Function,
				required: true,
			},
		},
		setup(props, ctx) {
			const wrapperValue = computed(() => {
				// if(props.value == null || Object.keys(props.value.totalRevenueMap).length == 0) {
				// 	return "-";
				// } else {
				// 	let result = "";
				// 	Object.keys(props.value.totalRevenueMap).forEach(currency => {
				// 		let record = 
				// 		result += "$" + formatNumber(props.value.totalRevenueMap[currency]) + " (" + currency + ") ";
				// 	})
				// 	return result;
				// }

				if(props.value == null || props.value.totalRevenueCurrency == null) {
					return "$0 (HKD)";
				} else {
					return "$" + formatNumber(Math.round(props.value.totalRevenue)) + " (" + props.value.totalRevenueCurrency + ")";
				}
			});

			return {
				backgroundColor: Colors.AndroidGreen,
				title: "Revenue",
				type: DataCardTypes.String,
				wrapperValue,
			};
		},
	};
</script>

<style scoped>
</style>