export default {
    Amaranth: "#E52B50",
    Orange: "orange",
    AndroidGreen: "#3DDC84",
    Azure: "#007FFF",
    Teal: "teal",
    Navy: "navy",

    Red900: "#bf360c",
    Red800: "#d84315",
    Red700: "#e64a19",

    Green900: "#1b5e20",
    Green800: "#2e7d32",
    Green700: "#388e3c",

    Yellow900: "#f57f17",
    Yellow800: "#f9a825",
    Yellow700: "#fbc02d",

    Text: "#6e6b7b"
}