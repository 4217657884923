<template>
	<div class="card p-2 mb-0 h-100">
		<el-table :data="items" style="width: 100%" size="mini" height="200px">
			<template v-for="tableHeader in fields">
				<el-table-column
					:fixed="tableHeader.prop == 'date'"
					min-width="50px"
					:width="tableHeader.prop != 'date' ? null : '165px'"
					:align="tableHeader.prop != 'date' ? 'right' : 'left'"
					:header-align="
						tableHeader.prop != 'date' ? 'right' : 'left'
					"
					:prop="tableHeader.prop"
					:label="tableHeader.label"
					:key="tableHeader.prop"
				>
				</el-table-column>
			</template>
		</el-table>
	</div>
</template>

<script>
	import { computed, onMounted, watch, ref } from "@vue/composition-api";
	import { BTable } from "bootstrap-vue";
	import store from "@/store";
	import { getLocalDateTimeString } from "@/utils/date-time-helper";
	import apis from "@configs/apis";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import { formatNumber } from "@/utils/ui-helper";
	import dayjs from "dayjs";
	import {
		X_AXIS_MODE_DATE,
		X_AXIS_MODE_MONTH,
		X_AXIS_MODE_YEAR,
	} from "@configs/filter";

	export default {
		components: {
			BTable,
		},
		setup(props, ctx) {
			const isLoading = ref(false);
			const loadFail = ref(false);
			const lastUpdate = ref(null);
			const fields = ref([]);
			const items = ref([]);

			onMounted(() => {
				fetchTable();
			});

			watch(
				() => store.getters["kParcel/GET_QUERY_PARAMS"],
				function () {
					fetchTable();
				}
			);

			async function fetchTable() {
				isLoading.value = true;
				loadFail.value = false;

				try {
					const queryParams = store.getters["kParcel/GET_QUERY_PARAMS"];
					let firstDay = queryParams.currentStartDate;

					const response = await fetcher(
						apis.kPacelShippedParcelTable,
						FetchMethod.GET,
						queryParams
					);

					const formatDate = (date) => {
						if (store.getters["kParcel/GET_QUERY_PARAMS"].xAxisMode == X_AXIS_MODE_DATE) {
							return dayjs(date).format('DD');
						} else if (store.getters["kParcel/GET_QUERY_PARAMS"].xAxisMode == X_AXIS_MODE_MONTH) {
							return dayjs(date).format('MMM');
						} else {
							return dayjs(date).format('YYYY');
						}
					}

					let dateLabel;
					if (store.getters["kParcel/GET_QUERY_PARAMS"].xAxisMode == X_AXIS_MODE_DATE) {
						dateLabel = "Client (" + dayjs(firstDay).year() + ")";
					} else if (store.getters["kParcel/GET_QUERY_PARAMS"].xAxisMode == X_AXIS_MODE_MONTH) {
						dateLabel = "Client (" + dayjs(firstDay).year() + ")";
					} else {
						dateLabel = "Client";
					}

					fields.value = [
						{
							prop: "date",
							label: dateLabel,
						},
						...Object.keys(response.itemMap).map((date) => {
							return {
								prop: formatDate(date),
								label: formatDate(date),
							};
						}),
					];

					let itemArr = [];
					response.clients.forEach((clientName) => {
						let result = { date: clientName };

						let atLeastOneData = false;
						Object.keys(response.itemMap).forEach((date) => {
							result[formatDate(date)] =
								response.itemMap[date][clientName] || 0;
							if (result[formatDate(date)] == 0) {
								result[formatDate(date)] = "-";
							} else {
								atLeastOneData = true;
								result[formatDate(date)] = formatNumber(
									result[formatDate(date)]
								);
							}
						});

						if (atLeastOneData) {
							itemArr.push(result);
						}
					});
					items.value = itemArr, //[...itemArr, ...itemArr, ...itemArr];
					lastUpdate.value = getLocalDateTimeString();
				} catch (e) {
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
			}

			return {
				fields,
				items,
			};
		},
	};
</script>

<style scoped>
	#other-header {
		text-align: right !important;
	}

	#other-cell {
		width: 100px;
		text-align: right;
	}
</style>