<template>
	<div class="h-100" id="root">
		<k-parcel-filter />

		<data-card-list />

		<div class="pb-2" style="flex: 1">
			<VueSlickCarousel v-bind="settings" class="remaining-area pt-1">
				<div class="container-fluid p-0 pl-1 pr-1 h-100">
					<div class="row h-50">
						<div class="col-12 h-100 p-1">
							<shipped-parcel :productName="productName" />
						</div>
					</div>

					<div class="row h-50">
						<div class="col-12 h-100 p-1">
							<shipped-parcel-table />
						</div>
					</div>
				</div>

				<div class="container-fluid p-0 pl-1 pr-1 h-100">
					<div class="row h-50">
						<div class="col-12 h-100 p-1">
							<shipped-weight :productName="productName" />
						</div>
					</div>

					<div class="row h-50">
						<div class="col-12 h-100 p-1">
							<shipped-weight-table />
						</div>
					</div>
				</div>
			</VueSlickCarousel>
		</div>
	</div>
</template>

<script>
	import store from "@/store";
	import { computed, onBeforeMount, ref } from "@vue/composition-api";
	import KParcelFilter from "./components/KParcelFilter.vue";
	import DataCardList from "./components/data-cards/operation/DataCardList.vue";
	import ShippedParcel from "./components/charts/operations/ShippedParcel.vue";
	import ShippedParcelTable from "./components/charts/operations/ShippedParcelTable.vue";
	import ShippedWeight from "./components/charts/operations/ShippedWeight.vue";
	import ShippedWeightTable from "./components/charts/operations/ShippedWeightTable.vue";
	import VueSlickCarousel from "vue-slick-carousel";

	import sizeHook from "@common-components/sizeHook";

	export default {
		components: {
			KParcelFilter,
			ShippedParcel,
			ShippedParcelTable,

			ShippedWeight,
			ShippedWeightTable,

			DataCardList,

			VueSlickCarousel,
		},
		setup(props, ctx) {
			const [size, element] = sizeHook();

			onBeforeMount(() => {
				store.dispatch("kParcel/FETCH_FROM_COUNTRY_IF_NEED");
				store.dispatch("kParcel/FETCH_TO_COUNTRY_IF_NEED");
			});

			const productName = computed(() => {
				const productId =
					store.getters["kParcel/GET_QUERY_PARAMS"].productId;
				if (productId == null) {
					return "Overall";
				} else {
					let title = "";
					store.getters["app/GET_PRODUCT_STATE"].list.forEach((pair) => {
						if (pair.value == productId) {
							title = pair.text;
						}
					});
					return title;
				}
			});

			return {
				size,
				element,
				productName,
			};
		},
	};
</script>

<style scoped>
	#root {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	#table-chart-container {
		display: flex;
		flex-direction: column;
		gap: 20px;
		height: 100%;
		width: 100%;
		margin-bottom: 20px;
	}
	.remaining-area {
		flex: 1;
	}
	.inner-chart {
		height: 350px !important;
	}
</style>