<template>
    <div :class="['card', 'h-100', customClass]" :style="`background: ${backgroundColor}`">
        <div class="card-body">
            <h5>{{ title }}</h5>

            <div v-if="isLoading">
                <b-skeleton animation="fade" width="85%" />
                <b-skeleton animation="fade" width="50%" />
            </div>

            <div v-else>
                <h1>{{ formattedValue }}</h1>
                <!-- <h1 v-show="postfix != null">{{ postfix }}</h1> -->
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import DataCardTypes from './data-card-types';
import { formatNumber } from '@/utils/ui-helper';
import { BSkeleton } from 'bootstrap-vue'


export default {
    props: {
        backgroundColor: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        value: {
            type: Number | String,
            required: true
        },
        prefix: {
            type: String,
            required: false
        },
        postfix: {
            type: String,
            required: false
        },
        customClass: {
            type: String,
            required: false
        },
        isLoading: {
            type: Boolean,
            required: false,
        }
    },
    components: {
        BSkeleton
    },
    setup(props, ctx) {
        const formattedValue = computed(() => {
            let result = props.value;
            if(props.type == DataCardTypes.Number || props.type == DataCardTypes.Dollar) {
                result = formatNumber(Math.round(result));
            }

            if(props.type == DataCardTypes.Dollar) {
                result = "$" + result;
            }

            if(props.type == DataCardTypes.Percentage) {
                result = result + "%";
            }

            if(props.postfix) {
                return result + " " + props.postfix;
            } else {
                return result;
            }
        });

        return {
            formattedValue
        }
    }
}
</script>

<style lang="css" scoped>
h5 {
    color: white;
}
h1 {
    color: white;
}
</style>