import { fetcher, FetchMethod } from "@/libs/axios";
import { ref, onMounted, watch } from "@vue/composition-api";
import {
    getZonedDatesFromFullDates,
    getZonedYearMonthFromFullDate,
    getZonedYearFromFullDate,
    getLocalDateTimeString,
} from "@/utils/date-time-helper";
import { X_AXIS_MODE_DATE, X_AXIS_MODE_MONTH } from "@configs/filter";
import { chartNumberFormatter } from '@/utils/ui-helper';
import store from "@/store";
import dayjs from "dayjs";

//fulfillment/UPDATE_DETAIL_CHART_SETTING
export default function ({
    detailTitle,
    detailApi,
    queryParams,
    chartApi,
    customFormatter,
    yAxisLabel,
    yAxisLabel2,
    customYAxis,
    customSeries,
    customLegend,
    queryMonthOffset,
}) {
    const isLoading = ref(false);
    const loadFail = ref(false);
    const lastUpdate = ref(null);
    const options = ref({});

    onMounted(() => {
        fetchChart();
    });

    watch(
        () => store.getters[queryParams],
        function () {
            fetchChart();
        }
    );

    function onReload() {
        fetchChart();
    }

    function onClick(params) {
        if (detailApi) {
            let queryStartDate = dayjs(store.getters[queryParams].currentStartDate);
            let queryEndDate;
            let titlePostfix = "";


            if(store.getters[queryParams].xAxisMode == X_AXIS_MODE_DATE) {
                queryStartDate = queryStartDate.date(params.dataIndex + 1);
                queryEndDate = queryStartDate.endOf('day');

                titlePostfix += "(" + queryStartDate.format('YYYY-MM-DD') + ")"
            } else if (store.getters[queryParams].xAxisMode == X_AXIS_MODE_MONTH) {
                queryStartDate = queryStartDate.month(params.dataIndex);
                queryEndDate = queryStartDate.endOf('month');

                titlePostfix += "(" + queryStartDate.format('YYYY MMMM') + ")"
            } else {
                queryStartDate = queryStartDate.year(queryStartDate.year() + params.dataIndex);
                queryEndDate = queryStartDate.endOf('year');

                titlePostfix += "(" + queryStartDate.format('YYYY') + ")"
            }

            const pieChartQueryParams = {
                ...store.getters[queryParams],
                currentStartDate: queryStartDate.toISOString(),
                currentEndDate: queryEndDate.toISOString(),
                previousEndDate: null,
                previousStartDate: null,
            }

            store.commit('app/UPDATE_DIALOG_PIE_CHART', { isOpen: true, apiUrl: detailApi, title: detailTitle, titlePostfix: titlePostfix, queryParams: pieChartQueryParams })
        }
    }

    function setOptions(response, queryParams) {
        let currentXAxis = Object.keys(response.currentBar);
        let previousXAxis = Object.keys(response.previousAccumulateLine);

        let xAxisData;
        let currentLabel;
        let previousLabel;

        if (queryParams.xAxisMode == X_AXIS_MODE_DATE) {
            currentLabel = getZonedYearMonthFromFullDate(
                queryParams.currentStartDate
            );
            previousLabel = getZonedYearMonthFromFullDate(
                queryParams.previousStartDate
            );
            xAxisData = getZonedDatesFromFullDates(
                currentXAxis.length > previousXAxis.length
                    ? currentXAxis
                    : previousXAxis
            );
        } else if (queryParams.xAxisMode == X_AXIS_MODE_MONTH) {
            // X_AXIS_MODE_MONTH
            currentLabel = getZonedYearFromFullDate(
                queryParams.currentStartDate
            );

            previousLabel = getZonedYearFromFullDate(
                queryParams.previousStartDate
            );
            xAxisData =
                currentXAxis.length > previousXAxis.length
                    ? currentXAxis
                    : previousXAxis;
        } else {
            currentLabel =
                getZonedYearFromFullDate(queryParams.currentStartDate) +
                "-" +
                getZonedYearFromFullDate(queryParams.currentEndDate);
            previousLabel =
                getZonedYearFromFullDate(queryParams.previousStartDate) +
                "-" +
                getZonedYearFromFullDate(queryParams.previousEndDate);

            xAxisData = Object.keys(response.currentBar);
        }


        let series = [
            {
                name: currentLabel,
                yAxisIndex: 0,
                data: Object.values(response.currentBar).map((val) => Math.round(val)),
                type: "bar",
                zlevel: 2,
            },
            {
                name: currentLabel + " (Accumulate)",
                yAxisIndex: 1,
                data: Object.values(response.currentAccumulateLine).map((val) => Math.round(val)),
                type: "line",
                smooth: true,
                symbol: 'none',
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
                zlevel: 1,
            },
            {
                name: previousLabel + " (Accumulate)",
                yAxisIndex: 1,
                data: Object.values(response.previousAccumulateLine).map((val) => Math.round(val)),
                type: "line",
                smooth: true,
                symbol: 'none',
                areaStyle: {},
                emphasis: {
                    focus: 'series'
                },
                zlevel: 0,
                itemStyle: { color: '#dddddd' },
            },
        ];

        let yAxis = [
            {
                type: "value",
                axisLabel: {
                    formatter: customFormatter || chartNumberFormatter
                },
                name: yAxisLabel,
                nameLocation: 'end',
            },
            {
                type: "value",
                axisLabel: {
                    formatter: customFormatter || chartNumberFormatter
                },
                name: yAxisLabel2,
                nameLocation: 'end',
            }
        ];

        let legend = {
            // data: [currentLabel, currentAccLabel, previousLabel],
        };

        options.value = {
            legend: customLegend != null ? customLegend(legend) : legend,
            xAxis: {
                type: "category",
                data: xAxisData,
            },
            yAxis: customYAxis != null ? customYAxis(yAxis) : yAxis,
            series: customSeries != null ? customSeries(series) : series,
        };
    }

    async function fetchChart() {
        isLoading.value = true;
        loadFail.value = false;

        let queryParamsObj = store.getters[queryParams]; //getWrapperQueryParams();

        try {
            const response = await fetcher(
                chartApi,
                FetchMethod.GET,
                queryParamsObj
            );

            setOptions(response, queryParamsObj);

            lastUpdate.value = getLocalDateTimeString()
        } catch (e) {
            console.log("Jack", e);
            loadFail.value = true;
        } finally {
            isLoading.value = false;
        }
    }

    return {
        isLoading,
        loadFail,
        lastUpdate,
        options,
        onReload,
        onClick
    };
}
