<template>
	<VueSlickCarousel v-bind="settings">
		<div
			class="gap h-100 w-100"
			v-for="component in components"
			:key="component"
		>
			<component :is="component" :isLoading="isLoading" :loadFail="loadFail" :value="value" :onReload="onReload" :lastUpdate="lastUpdate" />
		</div>
	</VueSlickCarousel>
</template>

<script>
	import VueSlickCarousel from "vue-slick-carousel";
	import TotalQty from "./TotalQty.vue";
	import TotalWeight from "./TotalWeight.vue";
	import TotalRevenue from "./TotalRevenue.vue";

	import store from "@/store";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import { ref, onMounted, watch } from "@vue/composition-api";
	import { getLocalDateTimeString } from "@/utils/date-time-helper";
	import apis from "@configs/apis";

	export default {
		components: {
			VueSlickCarousel,

			TotalQty,
			TotalWeight,
			TotalRevenue,
		},
		setup() {
			const isLoading = ref(false);
			const loadFail = ref(false);
			const lastUpdate = ref(null);
			const value = ref(null);

			onMounted(() => {
				fetchData();
			});

			watch(
				() => store.getters["kParcel/GET_QUERY_PARAMS"],
				function () {
					fetchData();
				}
			);

			function onReload() {
				fetchData();
			}

			async function fetchData() {
				isLoading.value = true;
				loadFail.value = false;

				try {
					const queryParams =
						store.getters["kParcel/GET_QUERY_PARAMS"];

					const response = await fetcher(
						apis.kParcelProductSummary,
						FetchMethod.GET,
						queryParams
					);

					value.value = response;
					lastUpdate.value = getLocalDateTimeString();
				} catch (e) {
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
			}

			return {
				isLoading,
				loadFail,
				lastUpdate,
				value,
				onReload,

				components: ["TotalQty", "TotalWeight", "TotalRevenue"],
				settings: {
					arrows: false,
					dots: false,
					centerMode: true,
					centerPadding: "0px",
					autoplay: true,
					focusOnSelect: true,
					infinite: true,
					slidesToShow: 3,
					speed: 500,

					responsive: [
						{
							breakpoint: 1400,
							settings: {
								slidesToShow: 3,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
						{
							breakpoint: 700,
							settings: {
								slidesToShow: 2,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
						{
							breakpoint: 590,
							settings: {
								slidesToShow: 1,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
					],
				},
			};
		},
	};
</script>

<style scoped>
	.gap {
		padding-left: 10px;
		padding-right: 10px;
	}
</style>