<template>
	<sidebar :is-open.sync="isOpenFilter">
		<template v-slot:openButton>
			<div>
				<feather-icon icon="FilterIcon" size="15" />
			</div>
		</template>

		<template v-slot:title>Filter</template>

		<template v-slot:body>
			<div class="customizer-section">
				<b-form-group label="Product" label-cols="4">
					<el-select v-model="productSelected" filterable placeholder="Select Product" :clearable="false">
						<el-option
							v-for="item in productState.list"
							:key="item.value"
							:label="item.text"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</b-form-group>

				<b-form-group label="Date Range" label-cols="4">
					<el-select
						v-model="rangeSelected"
						placeholder="Select Date"
					>
						<el-option-group
							v-for="group in rangeOptions"
							:key="group.text"
							:label="group.text"
						>
							<el-option
								v-for="item in group.options"
								:key="item.value"
								:label="item.text"
								:value="item.value"
							>
							</el-option>
						</el-option-group>
					</el-select>

					<el-date-picker
						class="mt-1 mb-1"
						v-show="rangeSelected == RANGE_CUSTOM_YEAR"
						type="year"
						v-model="customYearSelected"
						:editable="false"
						:clearable="false"
					/>
					<el-date-picker
						class="mt-1 mb-1"
						v-show="rangeSelected == RANGE_CUSTOM_MONTH"
						type="month"
						v-model="customMonthSelected"
						:editable="false"
						:clearable="false"
					/>
					
					<div
						class="mt-1 mb-1"
						v-show="rangeSelected == RANGE_CUSTOM_RANGE_YEAR"
					>
						<div>
							From:
							<el-date-picker
								type="year"
								v-model="customStartYearSelected"
								:editable="false"
								:clearable="false"
							/>
						</div>

						<div class="mt-1">
							To:
							<el-date-picker
								type="year"
								v-model="customEndYearSelected"
								:editable="false"
								:clearable="false"
							/>
						</div>
					</div>
				</b-form-group>

				<b-form-group label="Client" label-cols="4">
					<el-select v-model="clientSelected" filterable placeholder="Select Client" :clearable="false">
						<el-option
							v-for="item in clientOptions"
							:key="item.value"
							:label="item.text"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</b-form-group>
			</div>

			<div class="customizer-section">
				<b-form-group label="Business Type" label-cols="4">
					<el-select v-model="businessTypeSelected" filterable placeholder="Select Business Type" :clearable="false">
						<el-option
							v-for="item in businessTypeOptions"
							:key="item.value"
							:label="item.text"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</b-form-group>

				<b-form-group label="Order Status" label-cols="4">
					<el-select v-model="orderStatusSelected" filterable placeholder="Select Order Status" :clearable="false">
						<el-option
							v-for="item in orderStatusOptions"
							:key="item.value"
							:label="item.text"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</b-form-group>
			</div>

			<div class="customizer-section">
				<b-form-group label="Origin Country" label-cols="4">
					<el-select v-model="fromCountrySelected" filterable placeholder="Select From Country" :clearable="false">
						<el-option
							v-for="item in fromCountryState.list"
							:key="item.value"
							:label="item.text"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</b-form-group>

				<b-form-group label="Destintation Country" label-cols="4">
					<el-select v-model="toCountrySelected" filterable placeholder="Select Destintation Country" :clearable="false">
						<el-option
							v-for="item in toCountryState.list"
							:key="item.value"
							:label="item.text"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</b-form-group>
			</div>
		</template>
	</sidebar>
</template>

<script>
	import Sidebar from "@common-components/sidebar/Sidebar.vue";
	import { computed, ref, watch } from "@vue/composition-api";
	import {
		BFormGroup,
		BFormSelect,
		BFormRadioGroup,
		BFormCheckbox,
	} from "bootstrap-vue";
	import {
		BUSINESS_TYPE,
		Ranges,
		ORDER_STATUS_MAP,
		RANGE_CUSTOM_YEAR,
		RANGE_CUSTOM_MONTH,
		RANGE_CUSTOM_RANGE_YEAR
	} from "@configs/filter";
	import store from "@/store";
	import vSelect from "vue-select";

	export default {
		components: {
			BFormGroup,
			BFormSelect,
			Sidebar,

			BFormRadioGroup,
			BFormCheckbox,
			vSelect,
		},
		setup() {
			const isOpenFilter = ref(false);

			const productState = computed(
				() => store.getters["app/GET_PRODUCT_STATE"]
			);

			const productSelected = computed({
				get: () => store.state.kParcel.filter.productId,
				set: (val) => {
					store.commit("kParcel/UPDATE_FILTER_PRODUCT", val);
				},
			});

			const clientOptions = computed(
				() => [{text: "All", value: null}, ...store.getters["app/GET_CLIENT_STATE"].list]
			);

			const clientSelected = computed({
				get: () => store.state.kParcel.filter.clientId,
				set: (val) => {
					store.commit("kParcel/UPDATE_FILTER_CLIENT", val);
				},
			});

			const businessTypeSelected = computed({
				get: () => store.state.kParcel.filter.businessType,
				set: (val) => {
					store.commit("kParcel/UPDATE_FILTER_BUSINESS_TYPE", val);
				},
			});

			const orderStatusSelected = computed({
				get: () => store.state.kParcel.filter.orderStatus,
				set: (val) => {
					store.commit("kParcel/UPDATE_FILTER_ORDER_STATUS", val);
				},
			});

			const orderStatusOptions = computed(() => {
				return ORDER_STATUS_MAP[businessTypeSelected.value];
			});

			const rangeSelected = computed({
				get: () => store.state.kParcel.filter.range,
				set: (val) => {
					store.commit("kParcel/UPDATE_FILTER_RANGE", val);
				},
			});

			const fromCountryState = computed(
				() => store.getters["kParcel/GET_FROM_COUNTRY_STATE"]
			);

			const fromCountrySelected = computed({
				get: () => store.state.kParcel.filter.originalCountry,
				set: (val) => {
					store.commit("kParcel/UPDATE_FILTER_ORIGINAL_COUNTRY", val);
				},
			});

			const toCountryState = computed(
				() => store.getters["kParcel/GET_TO_COUNTRY_STATE"]
			);

			const toCountrySelected = computed({
				get: () => store.state.kParcel.filter.destinationCountry,
				set: (val) => {
					store.commit("kParcel/UPDATE_FILTER_DESTINATION_COUNTRY", val);
				},
			});

			const customYearSelected = computed({
				get: () => store.state.kParcel.filter.customYear,
				set: (val) => {
					store.commit("kParcel/UPDATE_FILTER_CUSTOM_YEAR", val);
				},
			});

			const customMonthSelected = computed({
				get: () => store.state.kParcel.filter.customMonth,
				set: (val) => {
					store.commit("kParcel/UPDATE_FILTER_CUSTOM_MONTH", val);
				},
			});

			const customStartYearSelected = computed({
				get: () => store.state.kParcel.filter.customStartYear,
				set: (val) => {
					store.commit("kParcel/UPDATE_FILTER_CUSTOM_START_YEAR", val);
				},
			});

			const customEndYearSelected = computed({
				get: () => store.state.kParcel.filter.customEndYear,
				set: (val) => {
					store.commit("kParcel/UPDATE_FILTER_CUSTOM_END_YEAR", val);
				},
			});

			return {
				productState,
				productSelected,

				clientOptions,
				clientSelected,

				businessTypeOptions: BUSINESS_TYPE,
				businessTypeSelected,

				orderStatusOptions,
				orderStatusSelected,

				rangeOptions: Ranges,
				rangeSelected,

				fromCountryState,
				fromCountrySelected,

				toCountryState,
				toCountrySelected,

				customYearSelected,
				customMonthSelected,

				isOpenFilter,

				RANGE_CUSTOM_YEAR,
				RANGE_CUSTOM_MONTH,
				RANGE_CUSTOM_RANGE_YEAR,

				customStartYearSelected,
				customEndYearSelected
			};
		},
	};
</script>

<style scoped>
	.customizer-section {
		padding: 1.5rem;
		border-bottom: 1px solid #ebe9f1;
	}
</style>
