<template>
  <div
    class="customizer d-md-block"
    :class="{'open': computeIsOpen}"
  >
    <!-- Toggler -->
    <b-link
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="computeIsOpen = !computeIsOpen"
    >
      <slot name="openButton"/>
      <!-- <feather-icon
        icon="SettingsIcon"
        size="15"
        class="spinner"
      /> -->
    </b-link>
    <!-- /Toggler -->

    <!-- Header -->
    <div class="customizer-section d-flex justify-content-between align-items-center">
      <div>
        <h4 class="text-uppercase mb-0">
          <slot name="title"/>
        </h4>
        <!-- <small>Customize &amp; Preview in Real Time</small> -->
      </div>
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="computeIsOpen = !computeIsOpen"
      />
    </div>
    <!-- Header -->

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-customizer-area scroll-area"
    >
      <slot name="body"/>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { computed } from '@vue/composition-api'

export default {
  components: {
    // BSV
    BLink,
    VuePerfectScrollbar,

  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, ctx) {
    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const computeIsOpen = computed({
      get: () => props.isOpen,
      set: (val) => {
        ctx.emit("update:isOpen", val);
      },
		});

    return {
      // Perfect Scrollbar
      perfectScrollbarSettings,
      computeIsOpen
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
  padding: 1.5rem;
  border-bottom: 1px solid #ebe9f1;
}

.ps-customizer-area {
  height: calc(100% - 83px)
}
</style>
